export const Switch = {
    // RadioGroup style is specified in the styles.js file
    // style object for base or default style
    baseStyle: {
        track: {
            padding: 1,
            background: "gray.dark",
            "[disabled] + &": {
                opacity: 1,
                background: "gray.lighter",
            },
            _focus: {
                boxShadow: "none",
            },
            _checked: {
                background: "yellow",
            },
        },
        // thumb: ,
    },
    // styles for different sizes ("sm", "md", "lg")
    sizes: {
        default: {
            container: {
                ["--switch-track-height"]: "1.5rem",
                ["--switch-track-width"]: "3rem",
            },
        },
        xl: {
            container: {
                ["--switch-track-height"]: { base: "2rem", md: "2.5rem" },
                ["--switch-track-width"]: { base: "4rem", md: "5rem" },
            },
        },
    },
    // styles for different visual variants ("outline", "solid")
    variants: {
        flexible: {
            track: {
                borderRadius: "full",
                background: "blue.darkestTransparent",
                padding: 0,
                _checked: {
                    background: "blue.darkestTransparent",
                },
                _focus: {
                    boxShadow: "none",
                },
            },
            thumb: {
                borderRadius: "full",
                bg: "yellow",
            },
        },
        flexibleUser: {
            track: {
                borderRadius: "full",
                background: "gray.lighterer",
                padding: 0,
                _checked: {
                    background: "gray.lighterer",
                },
                _focus: {
                    boxShadow: "none",
                },
            },
            thumb: {
                borderRadius: "full",
                bg: "yellow",
            },
        },
    },
    // default values for 'size', 'variant' and 'colorScheme'
    defaultProps: {
        colorScheme: "colorSchemes.yellow",
        size: "default",
    },
};
