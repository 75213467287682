import { theme } from "@chakra-ui/react";

/**
 * Grid system without a gutter. Used to calculate certain component sizes while being consistant...
 * Usage: 'sx={{ maxWidth: "grid.columns.3" }}'
 */
const grid = {
    columns: 12,
    padding: 20, // in px
    maxWidth: 1080, // in px
};

export const sizes = {
    mitFlexiiSidebarWidth: {
        xl: theme.space[64],
    },
    container: {
        sm: "640px",
        md: "768px",
        lg: "1024px",
        xl: "1280px",
    },
    grid: {
        ...grid,
        columns: {
            1: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 1 + "px", // 86.66px
            2: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 2 + "px", // 173.33px
            3: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 3 + "px", // 260px
            4: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 4 + "px", // 346.66px
            5: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 5 + "px", // 433.33px
            6: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 6 + "px", // 520px
            7: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 7 + "px", // 606.66px
            8: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 8 + "px", // 693.33px
            9: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 9 + "px", // 780px
            10: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 10 + "px", // 866.66px
            11: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 11 + "px", // 953.33px
            12: ((grid.maxWidth - grid.padding * 2) / grid.columns) * 12 + "px", // 1040px
        },
    },
};
