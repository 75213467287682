// https://chakra-ui.com/docs/styled-system/theming/component-style#styling-single-part-components
import { textSpecs, defaultFontSize } from "@/theme/foundations/typography";
import { spring } from "@/theme/animations";

// Button styled as as the Flexii "i"
const iButton = {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    height: 16,
    paddingY: 0,
    paddingX: 2,
    color: "white",
    background: "#2E83E9",
    background: "gradients.button",
    transitionDuration: "0.6s",
    borderRadius: "100px",
    minWidth: "250px",
    _hover: {
        ".chakra-button__icon": {
            transform: "translateX(-16px)",
        },
    },
    ".chakra-button__icon": {
        display: {
            base: "none",
            sm: "flex",
        },
        alignItems: "center",
        justifyContent: "center",
        height: "66px",
        width: "66px",
        position: "absolute",
        left: "100%",
        backgroundColor: "#2E83E9",
        marginLeft: 6,
        borderRadius: "inherit",
        transitionDuration: "0.2s",
        transitionTimingFunction: "ease-in-out",
    },
};

export const Button = {
    // style object for base or default style
    baseStyle: {
        paddingY: 5,
        paddingX: 14,
        position: "relative",
        maxWidth: "full",
        marginBottom: 2,
        zIndex: "base",
        fontSize: "h4",
        lineHeight: "normal",
        fontWeight: "black",
        borderRadius: "button",
        whiteSpace: "initial",
        gap: 5,
        transitionTimingFunction: spring,
        _disabled: {
            cursor: "default",
        },
        ".chakra-button__icon": {
            margin: 0,
        },
    },

    // styles for different sizes ("sm", "md", "lg")
    sizes: {
        inline: {
            padding: 0,
        },
        full: {
            width: "full",
        },
    },

    // styles for different visual variants ("outline", "solid")
    variants: {
        action: {
            // Yellow gradient with default text color
            color: "text",
            overflow: "hidden",
            background: "rgb(235,172,41)",
            background:
                "linear-gradient(53deg, rgba(235,172,41,1) 7%, rgba(255,214,0,1) 78%)",
            transitionDuration: "0.6s",
            "svg path": {
                fill: "currentColor",
            },
            _hover: {
                opacity: 0.8,
            },
            _disabled: {
                pointerEvents: "none",
                background: "gray.dark",
                background: "linear-gradient(53deg, #B8B8B8 7%, #d6d6d6 78%)",
                color: "white",
            },
        },
        ghost: {
            // Transparent with gray text
            color: "gray.dark",
            transitionDuration: "0.6s",
            "svg path": {
                fill: "currentColor",
            },
            _hover: {
                color: "text",
                background: "none",
            },
        },
        linkArrow: {
            // Text link with arrow
            ".chakra-button__icon": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                left: "100%",
                marginLeft: 3,
                transitionDuration: "0.2s",
                transitionTimingFunction: "ease-in-out",
                background: "inherit",
            },
            color: "blue.darkest",
            paddingY: 0,
            paddingX: 0,
            "svg path": {
                fill: "currentColor",
            },
            _hover: {
                ".chakra-button__icon": {
                    transform: "translateX(-4px)",
                },
            },
        },
        gray: {
            // Gray with default text color
            color: "text",
            backgroundColor: "gray.lighter",
            transitionDuration: "0.6s",
            _hover: {
                backgroundColor: "gray.lightest",
            },
        },
        disabled: {
            // Gray with default text color
            color: "text",
            backgroundColor: "gray.lighter",
            pointerEvents: "none",
            _hover: {
                background: "gray.lighter",
            },
        },
        rounded: {
            // Gray with default text color
            color: "text",
            borderWidth: "3px",
            borderColor: "gray.light",
            borderRadius: "50px",
            background: "none",
            transitionDuration: "0.6s",
            _hover: {
                borderColor: "gray.dark",
            },
        },
        anchorLink: {
            // Inline blue link button.
            display: "inline-flex",
            color: "blue.vibrant",

            transitionDuration: "0.6s",
            fontSize: 14,
            height: "fit-content",
            marginBottom: "0px",
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: `${
                (defaultFontSize * textSpecs.base.lineHeight -
                    defaultFontSize) /
                2
            }px`,
            _focus: {
                boxShadow: "none",
            },
            _hover: {
                color: "blue.light",
                textDecoration: "underline",
            },
        },
        mingle: {
            // Button styled as as the Flexii "i", but tilted on the side
            ...iButton,
        },
        "mingle--white": {
            // Same as mingle, but with white background
            ...iButton,
            background: "white",
            color: "text",
            ".chakra-button__icon": {
                ...iButton[".chakra-button__icon"],
                background: "inherit",
            },
        },
        none: {
            // All button styling removed. Use for custom buttons
            padding: 0,
            display: "inline-flex",
            background: "rgba(0,0,0,0)",
        },
    },

    // default values for `size` and `variant`
    defaultProps: {
        variant: "action",
        size: null,
    },
};
