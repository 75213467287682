import { Checkbox } from "./Checkbox";

export const Radio = {
    // RadioGroup style is specified in the styles.js file
    // style object for base or default style
    baseStyle: {
        container: {
            alignItems: "flex-start",
        },
        control: {
            ...Checkbox.baseStyle.control,
            alignItems: "center",
            borderRadius: "50%",
            background: "transparent",
            _checked: {
                ...Checkbox.baseStyle.control._checked,
                background: "transparent",
                _before: {
                    content: `""`,
                    display: "inline-block",
                    position: "relative",
                    width: "50%",
                    height: "50%",
                    background: "blue.darkest",
                },
            },
        },
        label: {
            ...Checkbox.baseStyle.label,
            paddingTop: 1,
        },
    },
    // styles for different sizes ("sm", "md", "lg")
    sizes: {},
    // styles for different visual variants ("outline", "solid")
    variants: {
        bold: {
            container: {
                width: "100%",
            },
            label: {
                width: "100%",
                fontSize: "14px",
                _checked: {
                    fontWeight: "bold",
                },
            },
        },
    },
    // default values for 'size', 'variant' and 'colorScheme'
    defaultProps: {
        size: null,
        variant: null,
        colorScheme: null,
    },
};
