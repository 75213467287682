import { signOut as nextAuthSignOut } from "next-auth/react";
import { dataLayerUserIdlocalStorageId } from "./gtm";

/**
 * Extend next-auth SignOut
 * @param {SignOutParams} params
 */
const signOut = (params) => {
    localStorage.removeItem(dataLayerUserIdlocalStorageId); // remove tracking userId
    nextAuthSignOut(params);
};

export const auth = {
    signOut,
};
