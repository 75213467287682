import { useEffect, useState } from "react";

/**
 * Returns a boolean specifying if the component had it's initial render or not.
 *
 * @example
 * Can be used in useEffects:
 *
 *        function MyComponent() {
 *            const componentDidMount = useComponentDidMount();
 *            useEffect(() => {
 *                console.log("First render");
 *                if (componentDidMount) {
 *                    console.log("All consecutive renders");
 *                }
 *            }, []);
 *            return <div>useComponent</div>;
 *        }
 *
 * @returns {boolean}
 */
export function useComponentDidMount() {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        if (!mounted) setMounted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return mounted;
}
