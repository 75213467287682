import { normalize } from "@sentry/core";

/**
 * Blacklisted key names in form of regex
 */
const keyBlacklist = [
    // some key's are already blacklisted by Sentry: https://docs.sentry.io/product/data-management-settings/scrubbing/server-side-scrubbing/
    "address", // may be too generic, but better to opt-out then opt-in
    "email",
    "username", // email synonym
    "users", // list of email
    "firstName",
    "lastName",
    "cpr",
    "personalId", // cpr synonym
    "sex",
    "formattedName",
    "birthdate",
    "birthYear",
    "protectionStartDate",
    "formattedName",
    "coName",
    "locality",
    "streetName",
    "city",
    "postalCode",
    "houseNumber",
    "floor",
    "buildingNumber",
    "sideNumber",
    "privateAddress",
    "token",
    "msisdn", // phone number
    "anumber", // anumber, phonenumber,
    "phonenumber",
    "accountId",
    "gender",
    "zip",
    "contactInformation",
    "contactAddress",
    "shopperIp",
    "authCode",
    "merchantReference",
    "merchantAccountCode",
].map((word) => new RegExp(`^.*?${word}.*$`, "i"));

/**
 * Callback function with the logic that scrubs data from a key's value
 *
 * !!! ATTENTION !!!
 * The scrubCallback may run in NodeJS or any browser.
 * In regex, positive lookbheind "(?<=)" and positive lookahead "(?=)" are not
 * supported in Safari. You must look for alternative ways.
 */
const cprRegex = /((0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4})/g;
const scrubCallback = (val) => {
    if (typeof val === "string") {
        // scrub cpr
        val = val.replaceAll(cprRegex, "__CPR_CENSORED__");

        // scrub email (source: https://stackoverflow.com/a/46181)
        val = val.replaceAll(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi,
            "__EMAIL_CENSORED__"
        );

        // scrub reserved phone number from reserve number request
        val = val.replaceAll(
            /anumbers\/(.*?)\/reserve/g,
            "anumbers/__RESERVED_NUMBER_CENSORED__/reserve"
        ); // capture group and replace

        // scrub reserved phone number from reserved number url (Flexii API)
        val = val.replaceAll(
            /checkout\/reserve-number\?preferredNumber=([^&]+)/gi, // "[^&]+" matches one or more characters that are not "&"
            "checkout/reserve-number?preferredNumber=__RESERVED_NUMBER_CENSORED_v2_"
        ); // capture group and replace
    }

    if (typeof val === "number") {
        val = val.toString(); // convert to string so that we can apply modifications

        val = val.replaceAll(cprRegex, "0"); // empty

        val = Number(val); // convert back to number
    }
    return val;
};

/**
 * Combine the processing of 'beforeSend' and 'beforeTransaction'
 * @param {Event} event Sentry Event object available in the beforeSend hook
 * @returns {Event} Modified event with scrubbed data
 */
const eventHandler = (event) => {
    // separate required fields from the rest
    let { message } = event;
    const {
        event_id,
        timestamp,
        platform,
        user,
        // temporary data storage for the SDK's event processing pipeline that is not sent to Sentry
        // of unserializable object type https://nodejs.org/api/http.html#class-httpincomingmessage containing circular references
        sdkProcessingMetadata,
        ...eventPayload
    } = event;

    // Some messages are created from a console.error, which sometimes are literally parsed from an object and not an Error object
    if (message && message === "[object Object]") {
        // if possible, give a more meaningful message from extra arguments
        message = eventPayload?.extra?.arguments?.[0].message || message;
        message = scrubCallback(message);
    }

    // Ignore a set of common errors
    const removeErrorPatterns = [
        /Hydration failed because the initial UI does not match what was rendered on the server/i,
        /There was an error while hydrating. Because the error happened outside of a Suspense boundary/i,
        /Minified React error/i, // Sentry commonly parses above two messages actually as e.g. "Minified React error #418; visit https://reactjs.org/docs/error-decoder.html?invariant=418 for the full..."

        /Abort fetching component for route:/i,
    ];
    if (
        matchesPattern(
            removeErrorPatterns,
            eventPayload?.exception?.values?.[0]?.value
        ) ||
        matchesPattern(
            removeErrorPatterns,
            eventPayload?.breadcrumbs?.slice(-1)?.[0].message
        )
    ) {
        // We dont discard 10% of the times in case its a highly frequent error
        // which still would comulate enough error reports for us to inspect
        if (Math.random() > 0.1) {
            return null; // discard event
        }
    }

    // Scrub on the whole event
    const filteredEvent = scrubData(eventPayload, keyBlacklist, scrubCallback);

    return {
        ...filteredEvent,
        event_id,
        timestamp,
        platform,
        ...(message ? { message } : {}), // reattach only when value actually exists
        ...(sdkProcessingMetadata ? { sdkProcessingMetadata } : {}), // reattach only when value actually exists
        // excluding 'user' as we want to remove any user data
    };
};

/**
 * !!! WARNING !!!
 * ignoreErrors ONLY WORKS ON THE `event.message` field. This field is frequently
 * empty/undefined in the SDK event payload and/or first populated when handled on Sentry servers,
 * as it is inferred from other fields.
 * @see https://github.com/getsentry/sentry-python/issues/770#issuecomment-665351605
 * @see https://github.com/getsentry/sentry-javascript/issues/6295#issuecomment-1419375463
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#decluttering-sentry
 *
 * It's recommended to use our custom `removeErrors` instead which filters on the
 * `beforeSend` hook and applyes to the field which may infer the message value.
 */
export const ignoreErrors = [
    /Non-Error exception captured/i, // Filter out "console.error" which are not logging an Error object
];
export const beforeSend = eventHandler;
export const beforeSendTransaction = eventHandler;

/**
 * =======================
 * HELPER FUNCTION
 * =======================
 */

/**
 * Scrub sensitive data out from Objects.
 * Filters throught both keys and values.
 * - deleting a key/value if the keyname is found in a blacklist
 * - applies a callback function on primitive values, replacing the original value
 *
 * @param {object} initObj Object to scrub data from
 * @param {array<string | regex> | null} keyBlacklist Array of blacklisted object keys
 * @param {function(primitive) => string | undefined} scrubFromValueCallback A function that filters through primitive values
 * @returns {object} Filtered object
 */
function scrubData(initObj, keyBlacklist, scrubFromValueCallback) {
    let eventObj;
    try {
        // clone and serialize the object, removing functions and other non JSON-conform values
        eventObj = JSON.parse(JSON.stringify(initObj));
    } catch (error) {
        // if clone failed due to non-serializable types (circular reference), use sentry's
        // internal cloning tool with a performance impact
        eventObj = normalize(initObj);
    }

    // Traverse through all nested objects and arrays until a primitive value is found (aka, no more nested values)
    // Modifies directly on the cloned object
    function traverseAndFilter(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === "object" && obj[key] !== null) {
                    // The current property is an object or an array,
                    // so we need to traverse it recursively
                    traverseAndFilter(obj[key]);
                } else if (isJsonObject(obj[key])) {
                    // The current property is a JSON object
                    // Parse it, apply scrubbing and serialize it again
                    const parsedJSON = JSON.parse(obj[key]);
                    traverseAndFilter(parsedJSON); // scrub directly on parsedJSON since it's already a copy and not reference
                    obj[key] = JSON.stringify(parsedJSON);
                } else {
                    // The current property has a primitive value - no more nested values
                    // Do work on this property
                    const isBlacklist =
                        keyBlacklist?.some((reg) => reg.test(key)) || false;
                    if (isBlacklist) {
                        // key has a blacklisted word. Delete value
                        delete obj[key];
                    } else {
                        // Key not blacklisted, do stuff on the value
                        if (scrubFromValueCallback) {
                            // Run the callback function over the value
                            obj[key] = scrubFromValueCallback(obj[key]);
                        }
                    }
                }
            }
        }
    }

    traverseAndFilter(eventObj);

    return eventObj; // return cloned and modified object
}

/**
 * Check if valid JSON object or array
 * Fails for any other valid json structure like null, false, true,...
 * Fails when single quotes are used instead of double quotes
 * @see https://stackoverflow.com/a/32278428
 * @param {string} str
 * @returns {boolean}
 */
function isJsonObject(str) {
    try {
        const parsedJSON = JSON.parse(str);
        return (
            !!(parsedJSON && str) &&
            (parsedJSON instanceof Array || parsedJSON instanceof Object
                ? true
                : false)
        );
    } catch (e) {
        return false;
    }
}

/**
 * Check if a target string matches against an array of regexes or literal strings.
 * Returns false if targetStr is undefined
 * @param {array<regex | string>} patterns Regex or strings to match the target string against
 * @param {string} targetStr A string to match against
 * @returns boolean
 */
function matchesPattern(patterns = [], targetStr) {
    if (targetStr === undefined) {
        return false;
    }
    return patterns.some((pattern) =>
        pattern instanceof RegExp
            ? pattern.test(targetStr)
            : pattern === targetStr
    );
}
