import { spreadKeysFromObject } from "../utils";
import { Checkbox } from "./components";
import { colors } from "./foundations/colors";
import { textSpecs, typography } from "./foundations/typography";
import { datePickerCss } from "@/components/primitives/DatePicker";

const styles = {
    global: {
        "html, body": {
            margin: 0,
            padding: 0,
            height: "100%",
        },
        html: { fontSize: textSpecs.base.fontSize, color: colors.text },
        body: { bg: "white", color: colors.text },
        "#__next": { height: "100%" },

        ...spreadKeysFromObject(
            ["h1", "h2", "h3", "h4", "h5", "h6"],
            textSpecs
        ),
        p: textSpecs.base,
        "b, strong": {
            fontWeight: typography.fontWeights.black,
        },
        a: {
            color: "inherit",
            textDecoration: "none",
        },
        "ul, ol": {
            paddingLeft: 5,
        },

        // Hacky override since it's not possible to theme the <RadioGroup> component
        ".chakra-radio-group": {
            marginBottom: Checkbox.baseStyle.container.marginBottom,
        },

        // Overwriting browser behavior for disabled and autofill fields :-(
        [`
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:disabled,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            textarea:disabled,
        `]: {
            borderColor: colors.text,
            webkitTextFillColor: colors.text,
            webkitBoxShadow: `0 0 0px 1000px ${colors.white} inset`,
            transition: "background-color 5000s ease-in-out 0s",
        },

        button: {
            _disabled: {
                cursor: "default",
            },
        },
        ...datePickerCss,
    },
};

export { styles };
