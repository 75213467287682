import { extendTheme } from "@chakra-ui/react";

// Global style overrides
import { styles } from "./styles";

// Foundational style overrides
import { colors } from "./foundations/colors";
import { breakpoints } from "./foundations/breakpoints";
import { sizes } from "./foundations/sizes";
import { typography } from "./foundations/typography";
import { borderRadius } from "./foundations/borderRadius";

import * as allComponents from "./components";

const overrides = {
    // Global style overrides
    styles,

    // Theming/Foundational style overrides
    colors,
    breakpoints,
    sizes,
    ...typography,
    ...borderRadius,

    // Components style overwrites
    components: {
        ...allComponents,
        RadioGroup: { marginBottom: "4rem" },
        Popover: {
            baseStyle: {
                popper: {
                    zIndex: "popover",
                },
            },
        },
    },
    // Config
    config: { cssVarPrefix: "flexii" },
};

const theme = extendTheme(overrides);

export { theme };
