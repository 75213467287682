import { hasAcceptedCookies } from "@/utils";

export const optimizelyInit = () => {
    // Check if window is defined (i.e. if we're in the browser)
    if (typeof window === "undefined") return;

    try {
        window.optimizely = window.optimizely || [];
        // Hold events until consent is given
        window.optimizely.push({ type: "holdEvents" });

        const handleConsentGiven = () => {
            if (hasAcceptedCookies("cookie_cat_statistic")) {
                window.optimizely.push({ type: "sendEvents" });
            }
        };

        // Make sure only one event listener is attached
        window.removeEventListener(
            "CookieInformationConsentGiven",
            handleConsentGiven
        );
        window.addEventListener(
            "CookieInformationConsentGiven",
            handleConsentGiven
        );
    } catch (error) {
        console.error("Failed to initialize Optimizely:", error);
    }
};
