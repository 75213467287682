const replacementString = (key) => `{{${key}}}`;

// Example: tokenReplacer('Hello {{name}}', { name: 'John' }) => 'Hello John'
export const tokenReplacer = (text, replacements) => {
    Object.keys(replacements).forEach((key) => {
        text = text.replaceAll(replacementString(key), replacements[key]);
    });

    return text;
};
