/**
 * Google Tag Manager
 *
 * Inspired by Next.js example: https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
 */
export const GTM_ID = process?.env?.NEXT_PUBLIC_GOOGLE_TRACKING_ID;

export const gtmPageView = (url) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event: "pageview",
        page: url,
    });
};

/**
 * Check for authentication status and set
 * hashed user id in localStorage.
 *
 * This value is the userId used for dataLayer.
 * REMEMBER DELETING THE localstorage ITEM AFTER SIGNOUT!
 */
export const dataLayerUserIdlocalStorageId = "datalayer_user_id";
export const gtmPrepareAuthStatusForTracking = async () => {
    // Check if authenticated status exists in localstorage
    if (localStorage.getItem(dataLayerUserIdlocalStorageId)) return undefined; // exists, skip following step
    const res = await fetch("/api/v1/auth/session");
    const data = await res.json();
    if (data?.userId) {
        // User authenticated and has a userId
        // Set in localstorage to retrieve for dataLayer
        localStorage.setItem(dataLayerUserIdlocalStorageId, data.userId);
    }
};
