export const Tooltip = {
    // style object for base or default style
    baseStyle: {
        ["--tooltip-bg"]: "white", // applies to box and arrow background
        borderRadius: "button",
        fontWeight: "bold",
        padding: 2,
        color: "text",
        boxShadow: "0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"
    },
    // styles for different sizes ("sm", "md", "lg")
    sizes: {},
    // styles for different visual variants ("outline", "solid")
    variants: {
        gray: {

        }
    },
    // default values for 'size', 'variant' and 'colorScheme'
    defaultProps: {},
};
