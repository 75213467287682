export const themeColors = {
    black: "#000000",
    white: "#FFFFFF",
    purple: "#7E0ECF",
    yellow: "#FFCA0F",
    gray: {
        lightest: "#F6F6F6",
        lighterer: "#eeeeee",
        lighter: "#E4E4E4",
        lighterTransparent80: "#E4E4E480",
        lighterTransparent: "#E4E4E440",
        light: "#d6d6d6",
        dark: "#B8B8B8",
    },
    blue: {
        darkest: "#000055",
        darkestTransparent: "#00005580",
        darkestTransparenter: "#00005560",
        dark: "#28249B",
        vibrant: "#00AEFF",
        light: "#64CAFF",
        lighter: "#E1F4FE",
        lightest: "#EEFAFF",
    },
    red: "red",
    gradients: {
        main: "linear-gradient(88.15deg, #7D0ECF 17.7%, #0E9EFB 105.33%)",
        button: "linear-gradient(90deg, #7E11D1 0%, #2E83E9 100%)",
        productCard:
            "linear-gradient(214.06deg, #6C8FB0 20.75%, #08AFF9 100.14%)",
    },
};

export const colors = {
    // Theme Colors
    ...themeColors,

    // Named colors
    error: themeColors.red,
    info: themeColors.yellow,
    text: themeColors.blue.darkest,
    cta: themeColors.yellow,

    /**
     * Chakra colorScheme props is using {color.500}
     * So define color schemas here and reference like so: <Element colorScheme={colorSchemes.yellow}/>
     */
    colorSchemes: {
        yellow: {
            500: themeColors.yellow,
        },
    },
};
