import { borderRadius } from "../foundations/borderRadius";
import { colors } from "../foundations/colors";

const borderThickness = 3; // in px

/**
 * Checkbox theming
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/checkbox.ts
 */
export const Checkbox = {
    // style object for base or default style
    baseStyle: {
        container: {
            marginBottom: ".5rem",
        },
        label: {
            marginStart: 2,
            _invalid: {
                color: "error",
            },
        },
        icon: {
            stroke: "black !important",
        },
        control: {
            alignSelf: "flex-start",
            border: `${borderThickness}px solid`,
            height: `calc(25px + ${borderThickness}px)`,
            width: `calc(25px + ${borderThickness}px)`,
            borderRadius: borderRadius.radii.button,
            marginTop: `${borderThickness}px`,
            background: "transparent",
            transition: "background .3s, border .3s",
            margin: 0,
            _hover: {
                borderColor: "blue.darkest",
            },
            _invalid: {
                borderColor: colors.error,
            },
            _checked: {
                borderColor: "blue.darkest",
            },
            _focus: {
                boxShadow: "none",
                outline: "none !important",
            },
            _focusVisible: {
                boxShadow: "none",
                outline: "none !important",
            },
            _invalid: {
                borderColor: "error",
            },
        },
    },
    // styles for different sizes ("sm", "md", "lg")
    sizes: {},
    // styles for different visual variants ("outline", "solid")
    variants: {},
    // default values for 'size', 'variant' and 'colorScheme'
    defaultProps: {
        size: null,
        variant: null,
        colorScheme: null,
    },
};
