// https://chakra-ui.com/docs/styled-system/theming/component-style#styling-single-part-components

export const Heading = {
    // Styles for the base style
    baseStyle: {
        fontWeight: "black",
    },
    // Styles for the size variations
    sizes: {},
    // Styles for the visual style variations
    variants: {
        gradient: {
            display: "inline-block",
            background: "purple",
            background: "gradients.main",
            color: "transparent",
            webkitBackgroundClip: "text",
            backgroundClip: "text",
        },
    },
    // The default `size` or `variant` values
    defaultProps: { size: null },
};
