// https://chakra-ui.com/docs/styled-system/theming/component-style#styling-single-part-components

export const Divider = {
    // style object for base or default style
    baseStyle: {
        marginY: 5,
        opacity: 1,
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "white",
    },

    // styles for different sizes ("sm", "md", "lg")
    sizes: {},

    // styles for different visual variants ("outline", "solid")
    variants: {
        productCard: {
            marginY: 0,
            opacity: 0.1,
        }
    },

    // default values for `size` and `variant`
    defaultProps: {},
};
