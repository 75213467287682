import { spreadKeysFromObject } from "../../utils";
import { Nunito, Shadows_Into_Light } from "next/font/google";

const nunito = Nunito({ subsets: ["latin"] });
const shadows_into_light = Shadows_Into_Light({
    weight: "400",
    subsets: ["latin"],
});

export const defaultFontSize = 16;

export const textSpecs = {
    /**
     * Text Sizes are built using the 4pt system (aka a multiplaction of 4)
     */
    base: {
        fontSize: `${defaultFontSize}px`,
        lineHeight: "1.5",
        marginBottom: ".5rem",
    },
    h1: {
        fontSize: {
            base: "2.375rem", // ~ 36px
            xl: "5rem", // ~ 80px
        },
        lineHeight: "0.93",
        marginBottom: ".5rem",
    },
    h2: {
        fontSize: {
            base: "1.875rem", // ~ 30px
            xl: "2.375rem", // ~ 38px
        },
        lineHeight: "1.16",
        marginBottom: ".5rem",
    },
    h3: { fontSize: "1.62rem", lineHeight: "1.16", marginBottom: ".5rem" }, // ~ 26px
    h4: { fontSize: "1.25rem", marginBottom: ".5rem" }, // ~ 20px
    h5: { fontSize: "0.75rem", marginBottom: ".5rem" }, // ~ 12px
    h6: { fontSize: "0.75rem", marginBottom: ".5rem" }, // ~ 12px
};

export const typography = {
    fonts: {
        heading: `${nunito.style.fontFamily}, Arial, sans-serif`,
        body: `${nunito.style.fontFamily}, Arial, sans-serif`,
        handwritten: `${shadows_into_light.style.fontFamily}, ${nunito.style.fontFamily}, Arial, sans-serif`,
    },
    fontSizes: {
        ...spreadKeysFromObject(
            ["base", "h1", "h2", "h3", "h4", "h5", "h6"],
            textSpecs,
            (value) => value.fontSize
            // wrong formatting: because h1 & h2 have fontSize objects' - should be fixed in a PR bcz it already affects various views
        ),
    },
    lineHeights: {
        normal: 1.5,
    },
    fontWeights: {
        normal: 400,
        bold: 700,
        black: 900,
    },

    // In order to support responsive font-sizes, we use textStyles. Usage: <Text textStyle="h3">Example..</Text>
    textStyles: {
        ...textSpecs,
    },
};
