import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import RDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import {
    format,
    isValid as isValidDate,
    parse,
    isBefore,
    isAfter,
} from "date-fns";
import { Input } from "../Input";

import "react-datepicker/dist/react-datepicker.css";

import da from "date-fns/locale/da";
registerLocale("da", da);

/**
 * DatePicker component built on top of "react-daypicker" and using Flexii's "<Input/>" component.
 * Allows for:
 * - minDate
 * - maxDate
 * - range
 *
 * @param {string} [minDate] Optional minDate in form of "dd-MM-yyyy", e.g. "24-01-2023"
 * @param {string} [maxDate] Optional maxDate in form of "dd-MM-yyyy", e.g. "24-01-2023"
 */
export function DatePicker({
    minDate: minDateProp,
    maxDate: maxDateProp,
    onChange: onChangeProp,
    dateFormat: dateFormatProp = "yyyy-MM-dd",
    selected: selectedDateProp,
    inputProps,
    ...rest
}) {
    const [selectedDate, setSelectedDate] = useState(new Date()); // derive initial state from props
    const [prevSelectedState, setPrevSelectedState] = useState(null); // used to cross-check if props "selectedProps" has changed
    const [inputValue, setInputValue] = useState("");

    /**
     * Change "selectedDate" whenever the prop "selected" value is changed inside the parent component
     * May be seen as an antipattern by some
     * @see https://stackoverflow.com/a/75106486/3673659
     * @see https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
     */
    if (
        selectedDateProp &&
        prevSelectedState?.toDateString() != selectedDateProp?.toDateString()
    ) {
        if (isValidDate(selectedDateProp)) {
            setSelectedDate(selectedDateProp);
        }

        setPrevSelectedState(selectedDateProp);
    }

    // Check if minDate/maxDate props are valid
    useEffect(() => {
        verifyValidDateProp(minDateProp, "minDate");
        verifyValidDateProp(maxDateProp, "maxDate");

        if (!isDateInRange(selectedDate)) {
            setSelectedDate(minDateProp || maxDateProp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minDateProp, maxDateProp]);

    /**
     * Apply changes whenever the "selectedDate" value changes. Runs on first render too.
     */
    useEffect(() => {
        // Update "inputValue" to always reflect the dateFormatProp string of the "selectedDate" value
        setInputValue(convertDateToString(selectedDate, dateFormatProp));
        onChangeProp(selectedDate); // invoke the onChange callback to pass new date to parent component

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    /**
     * Handle a date selection in the date picker and update the selected state value
     * @param {date} newDate
     */
    const handleDatePickerSelect = (newDate) => {
        setSelectedDate(newDate);
    };

    /**
     * Handle every <input> key actions and update the "inputValue" state value.
     * @param {event} event
     */
    const handleInputChange = (event) => {
        event.preventDefault();
        setInputValue(event.currentTarget.value || event.target.value);
    };

    /**
     * Validate, after finished <input> inserts, if the value is a correct date value and within
     * the minDate and maxDate range. If true, apply date changes to the datepicker.
     * If false, use the previous value which likely was a valid date.
     * @param {event} event
     */
    const validateInputValue = () => {
        setSelectedDate((prevDate) => {
            const date = convertStringToDate(inputValue, dateFormatProp);
            if (isValidDate(date) && isDateInRange(date)) {
                return date;
            } else {
                setInputValue(convertDateToString(prevDate, dateFormatProp));
                return prevDate;
            }
        });
    };

    /**
     * Check if a date is inside minDate or maxDate range, if any has been specified
     * @param {date} date
     * @returns {boolean}
     */
    const isDateInRange = (date) => {
        // selectedDate is earlier than minDate allows
        if (minDateProp && isBefore(date, minDateProp)) return false;

        // date is later than maxDate allows
        if (maxDateProp && isAfter(date, maxDateProp)) return false;

        // date is in range
        return true;
    };

    // check if mounted preventing hydration mismatch errors, aka, differences in server side rendered and client side rendered components
    return (
        <RDatePicker
            locale="da"
            dateFormat={dateFormatProp}
            showPopperArrow={false}
            selected={selectedDate}
            onChange={handleDatePickerSelect}
            onChangeRaw={handleInputChange}
            onBlur={validateInputValue}
            {...{
                ...(minDateProp ? { minDate: minDateProp } : {}),
                ...(maxDateProp ? { maxDate: maxDateProp } : {}),
            }}
            {...rest}
            customInput={
                <Input
                    // onBlur={validateInputValue} // not working, parent hijacks the onBlur event which includes the input field
                    inputProps={{
                        value: inputValue || "",
                        tabIndex: 0,
                        ...inputProps,
                    }}
                    sx={{
                        width: "auto",
                        marginBottom: 0,
                        ...(inputProps?.sx ? inputProps : {}),
                    }}
                />
            }
        />
    )
}

DatePicker.propTypes = {
    dateFormat: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.instanceOf(Date),
};

/**
 * ========================
 * HELPER METHODS
 * ========================
 */

/**
 * Use below helpers to consistently convert between Date objects and strings of "dd-MM-yyyy" format and nothing else!
 */
export const convertDateToString = (date, dateFormat) =>
    format(date, dateFormat); // convert Date object to "dd-MM-yyyy" string
export const convertStringToDate = (text, dateFormat) =>
    parse(text, dateFormat, new Date()); // convert "dd-MM-yyyy" string to Date object

/**
 * Verify if prop exists and if valid Date object. Else throw error
 */
export const verifyValidDateProp = (date, varName) => {
    if (date && !isValidDate(date)) {
        throw new Error(`${varName} is not a valid date object.`);
    }
};
