import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { Input } from "@/components/primitives/Input/Input";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";

/**
 * Address input with danish address autocomplete
 * @see https://github.com/SDFIdk/dawa-autocomplete2
 *
 * @param {function} param0 Callback function when an address is selected
 * @returns {JSX.Element}
 */

export function AddressInput({ onAddressSelect, ...rest }) {
    const inputRef = useRef();
    const [dawaLoaded, setDawaLoaded] = useState(false);

    useIsomorphicLayoutEffect(() => {
        const script = document.createElement("script");
        script.src =
            "https://cdn.dataforsyningen.dk/dawa/assets/dawa-autocomplete2/1.0.2/unfilled/dawa-autocomplete2.min.js";
        script.async = true;
        script.onload = () => {
            setDawaLoaded(true);
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (!dawaLoaded || !inputRef.current) return;

        // Inject dawa Address Autocomplete
        const autoComplete = window.dawaAutocomplete.dawaAutocomplete(
            inputRef.current,
            {
                select: (selected) => onAddressSelect(selected),
            }
        );

        return () => autoComplete?.destroy(); // Remove dawa when finished

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dawaLoaded, inputRef.current]);

    return (
        <Box
            className="autocomplete-container"
            sx={{
                position: "relative",
                ".dawa-autocomplete-suggestions": {
                    marginTop: 1,
                    position: "absolute",
                    paddingLeft: 0,
                    top: "42px",
                    left: 0,
                    right: 0,
                    zIndex: "overlay",
                    maxHeight: 60,
                    overflowY: "auto",
                    borderWidth: "3px",
                    color: "text",
                    borderRadius: "button",
                    borderStyle: "solid",
                    borderColor: "text",
                    backgroundColor: "gray.lightest",
                },
                ".dawa-autocomplete-suggestions .dawa-autocomplete-suggestion":
                    {
                        margin: 0,
                        padding: 2,
                        listStyle: "none",
                        cursor: "pointer",
                        color: "#333",
                    },
                ".dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:first-of-type":
                    {
                        borderTopLeftRadius: "inherit",
                        borderTopRightRadius: "inherit",
                    },
                ".dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:last-of-type":
                    {
                        borderBottomLeftRadius: "inherit",
                        borderBottomRightRadius: "inherit",
                        borderBottomWidth: 2,
                    },
                ".dawa-autocomplete-suggestions .dawa-autocomplete-suggestion.dawa-selected, .dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:hover":
                    {
                        background: "gray.lighter",
                    },
            }}
        >
            <Input
                ref={inputRef}
                label="Adresse"
                id="dawa-autocomplete-input"
                type="text"
                zIndex="overlay"
                inputProps={{
                    autoComplete: "do-not-autofill",
                    // onBlur: event => onAddressSelect(event.target.value),
                }}
                {...rest}
            />
        </Box>
    );
}

AddressInput.propTypes = {
    onAddressSelect: PropTypes.func.isRequired,
};
