import { spring } from "../animations";
import { Button } from "./Button";
import { textSpecs, defaultFontSize } from "@/theme/foundations/typography";

export const Link = {
    // Styles for the base style
    baseStyle: {
        transitionTimingFunction: spring,
        _hover: {
            textDecoration: "none",
        },
    },
    // Styles for the size variations
    sizes: {
        ...Button.sizes
    },
    // Styles for the visual style variations
    variants: {
        inline: {
            textDecoration: "underline",
        },
        button: {
            textDecoration: "none",
            fontSize: "14px",
            color: "blue.vibrant",
            height: "fit-content",
            fontWeight: "black",
            paddingBottom: `${(defaultFontSize * textSpecs.base.lineHeight - defaultFontSize) / 2 }px`,
        },
        footer: {
            textDecoration: "none",
            fontSize: "14px",
            color: "text",
            lineHeight: "28px",
            _hover: {
                textDecoration: "underline",
            }
        },
    },
    // The default `size` or `variant` values
    defaultProps: {
        variant: "inline",
    },
};
