import { useMemo } from "react";

/**
 * Check if app runs locally in localhost for development purposes.
 * @returns {boolean} True or false when the app runs in "localhost" host.
 */
export const useIsLocalhost = () => {
    const isLocalhost = useMemo(
        () => window?.location?.hostname === "localhost",
        []
    ); // is run once in browser
    return isLocalhost;
};
