/**
 * Custom theming for the "react-datepicker" library
 * THIS IS NOT PART OF CHAKRA!
 *
 * This file is directly imported to "/theme/styles.js" to apply it on a global scope
 */
import { colors } from "@/theme/foundations/colors";
import { typography } from "@/theme/foundations/typography";

export const datePickerCss = {
    // Wrapper
    ".react-datepicker__tab-loop": {
        ".react-datepicker": {
            fontFamily: typography.fonts.body,
        },
        // Selected day
        ".react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range":
            {
                background: colors.purple,
            },
    },
};
