import { typography } from "@/theme/foundations/typography";
// https://chakra-ui.com/docs/styled-system/theming/component-style#styling-single-part-components

export const Text = {
    // Styles for the base style
    baseStyle: {},
    // Styles for the size variations
    sizes: {},
    // Styles for the visual style variations
    variants: {
        cardSectionHeadline: {
            display: "block",
            fontSize: "base",
            color: "gray.dark",
            fontWeight: "black",
            marginBottom: 0,
        },
        cardCopy: {
            fontSize: { base: "18px", md: "h4" },
            fontWeight: "black",
        },
        gradient: {
            display: "inline-block",
            fontWeight: "black",
            background: "purple",
            background: "gradients.main",
            color: "transparent",
            webkitBackgroundClip: "text",
            backgroundClip: "text",
        },
        productCardGradient: {
            display: "inline-block",
            fontWeight: "black",
            background: "blue.dark",
            background: "gradients.productCard",
            color: "transparent",
            webkitBackgroundClip: "text",
            backgroundClip: "text",
        },

        // Product card specific
        productCardSmallBlue: {
            color: "blue.light",
            fontWeight: "black",
            fontSize: "h5",
            textTransform: "uppercase",
        },
        handWritten: {
            fontFamily: typography.fonts.handwritten,
            letterSpacing: 2,
        },
    },
    // The default `size` or `variant` values
    defaultProps: { size: null },
};
