export const breakpoints = {
    "sm" : "480px",
    "md" : "768px",
    "lg" : "992px",
    "xl" : "1280px",
    "2xl": "1536px",
}

// Internally, this is transformed to
// const breakpoints = ['0px', '480px', '768px', '992px', '1280px']
