import { forwardRef } from "react";
import { Input } from "./Input";

function PhoneNumberInputComponent({ inputProps, ...rest }, ref) {
    const handleKeyDown = (e) => {
        const isDigit = /\d/.test(e.key);
        const isControlKey =
            e.key === "Backspace" ||
            e.key === "Delete" ||
            e.key === "ArrowLeft" ||
            e.key === "ArrowRight" ||
            (e.ctrlKey && e.key === "v") || // Allow paste with Ctrl+V
            (e.metaKey && e.key === "v"); // Allow paste with Command+V on Mac

        const currentValue = e.target.value;

        if (!isDigit && !isControlKey) {
            e.preventDefault();
        }

        if (isDigit && currentValue.length >= 8) {
            e.preventDefault();
        }
    };

    return (
        <Input
            inputProps={{
                type: "tel",
                inputMode: "numeric",
                onKeyDown: handleKeyDown,
                ...inputProps,
            }}
            {...rest}
            ref={ref}
            inputSx={{ _disabled: { cursor: "default" } }}
        />
    );
}

export const PhoneNumberInput = forwardRef(PhoneNumberInputComponent);
