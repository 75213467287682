import PropTypes from "prop-types"
import { DatePicker } from "./DatePicker";

import {
    isBefore,
    isAfter,
    differenceInDays,
    addDays,
    subDays,
} from "date-fns";
import { useEffect, useState } from "react";

import { verifyValidDateProp } from "./DatePicker";
import { Box, Flex } from "@chakra-ui/react";

/**
 * DateRangePicker built on top of the "./DatePicker"
 */
export function DateRangePicker({
    minDate: minDateProp,
    maxDate: maxDateProp,
    onChange: onChangeProp,
    dateFormat: dateFormatProp = "yyyy-MM-dd",
    sx,
    datePickerProps,
}) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [exampleDaysDifferences, setExampleDaysDifferences] = useState(30); // days differences between the initial set start and end date

    useEffect(() => {
        setStartDate(subDays(new Date(), exampleDaysDifferences)); // set initial differences

        verifyValidDateProp(minDateProp, "minDate");
        verifyValidDateProp(maxDateProp, "maxDate");

        // Check if the initial exampleDaysDifferences is longer than the allowed range between minDate & maxDate and update if true
        const daysDifferences = differenceInDays(minDateProp, maxDateProp);
        if (daysDifferences < exampleDaysDifferences) {
            setExampleDaysDifferences(daysDifferences);
        }

        // Check if initial startDate and endDate values have an acceptable days in difference
        if (minDateProp && isBefore(endDate, minDateProp)) {
            // startDate is earlier than minDate allows
            setStartDate(minDateProp);
            setEndDate(addDays(minDateProp, exampleDaysDifferences));
        }

        if (maxDateProp && isAfter(endDate, maxDateProp)) {
            // endDate is later than maxDate allows
            setStartDate(subDays(maxDateProp, exampleDaysDifferences));
            setEndDate(maxDateProp);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle value changes and forward it to the parent prop callback
    useEffect(() => {
        onChangeProp([startDate, endDate]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    return (
        <Flex
            sx={{
                // columnGap: 2, // creates moving gaps when selecting input fields
                alignItems: "center",
                ...sx,
            }}
        >
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat={dateFormatProp}
                {...{
                    ...(minDateProp ? { minDate: minDateProp } : {}),
                    ...(maxDateProp ? { maxDate: maxDateProp } : {}),
                }}
                {...datePickerProps}
                inputProps={{
                    sx:{
                        fontSize: ["16px", "h4"]
                    }
                }}
            />
            <Box sx={{
                height: "1px",
                width: 2,
            }}/>
            <Box
                sx={{
                    width: "35px",
                    height: "4px",
                    background: "text",
                    borderRadius: "7px"
                }}
            />
            <Box sx={{
                height: "1px",
                width: 2,
            }}/>
            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat={dateFormatProp}
                minDate={startDate}
                {...(maxDateProp ? { maxDate: maxDateProp } : {})}
                {...datePickerProps}
                inputProps={{
                    sx:{
                        fontSize: ["16px", "h4"]
                    }
                }}
            />
        </Flex>
    );
}

DateRangePicker.propTypes = {
  dateFormat: PropTypes.string,
  datePickerProps: PropTypes.any,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.any
}
