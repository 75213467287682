/**
 * @callback formatCallback
 * @param {any} value - The value of the key
 * @return {any} The formatted value of the key
 */

/**
 * Create a new first level object using a list of keys from an initial object.
 * The goal of the function is to create easy extendable spreads
 *
 * @example
 * const initObj = {
 *      a: "Ben",
 *      b: "Hello"
 *      c: "Jerry"
 * }
 * const newObj = spreadKeysFromObject(["a", "b"], initObj)
 *
 * console.log(newObj); // -> {a: "Ben", b:"Hello"}
 *
 * @param {array} keys An array of keys to spread out of the object
 * @param {object} object The object to spread keys out
 * @param {formatCallback} [formatting] Optional function to format the keys (for multilevel keys)
 * @returns {object}
 */
export function spreadKeysFromObject(keys, object, formatting = null) {
    return keys.reduce((newObject, key) => {
        newObject[key] =
            formatting != null ? formatting(object[key]) : object[key];
        return newObject;
    }, {});
}
