import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { GTM_ID, gtmPageView } from '@/services/client';

export function GoogleTag() {
    const router = useRouter();

    useEffect(() => {
        router.events.on('routeChangeComplete', gtmPageView)
        return () => {
          router.events.off('routeChangeComplete', gtmPageView)
        }
      }, [router.events])

    if (!GTM_ID) return null;

    return null; 
};
