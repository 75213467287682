import { colors } from "../foundations/colors";

export const Input = {
    // Styles for the base style
    baseStyle: {
        field: {
            border: "3px solid",
            color: "text",
            fontWeight: "black",
            fontSize: "h4",
            paddingY: 1,
            paddingX: 2,
            borderRadius: "button",
            _invalid: {
                borderColor: colors.error,
            },
            _groupInvalid: {
                borderColor: colors.error,
            },
            _disabled: {
                opacity: 1,
                color: colors.text,
                backgroundColor: colors.gray.lightest,
            },
            _groupDisabled: {
                opacity: 1,
                color: colors.text,
                backgroundColor: colors.gray.lightest,
            },
        }
    },
    // Styles for the size variations
    sizes: {},
    // Styles for the visual style variations
    variants: {},
    // The default `size` or `variant` values
    defaultProps: {
        size: null,
        variant:
            null /* !IMPORTANT! Do not remove variant nulling. Else baseStyle are not applied. */,
    },
};
