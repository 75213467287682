/**
 * yup danish error translations
 * @see https://github.com/jquense/yup#error-message-customization
 * @see https://github.com/jquense/yup/blob/master/src/locale.ts
 *
 *
 *
 * Usage:
 * In the Next.js' `_app.js` file, add these lines in the top:
 *
 *          import { yupDA } from "../locales/yupDA";
 *          import { setLocale } from "yup";
 *          setLocale(yupDA);
 *
 * This replaces the default error messages across the app.
 */

import printValue from "yup/lib/util/printValue";

export const yupDA = {
    mixed: {
        default: "${path} er ugyldig",
        required: "${path} mangler at blive udfyldt",
        oneOf: "${path} skal være en af følgende værdier: ${values}",
        notOneOf: "${path} må ikke være en af følgende værdier: ${values}",
        notType: ({ path, type, value, originalValue }) => {
            const castMsg =
                originalValue != null && originalValue !== value
                    ? //
                      ` (ændret fra værdien \`${printValue(
                          originalValue,
                          true
                      )}\`).`
                    : ".";

            return type !== "mixed"
                ? `${path} skal være af typen \`${type}\`, ` +
                      `men den endelige værdi var: \`${printValue(
                          value,
                          true
                      )}\`` +
                      castMsg
                : `${path} skal passe til den konfigurerede type. ` +
                      `Den validerede værdi var: \`${printValue(
                          value,
                          true
                      )}\`` +
                      castMsg;
        },
    },
    string: {
        length: "${path} skal være præcist ${length} tegn lang",
        min: "${path} skal være mindst ${min} tegn lang",
        max: "${path} må højst være  ${max} tegn lang",
        matches: '${path} skal matche følgende: "${regex}"',
        email: "Mailen skal altså være en gyldig én af slagsen – prøv igen! ",
        url: "${path} skal være en gyldig URL",
        trim: "${path} må ikke indeholde mellemrum, hverken i begyndelsen eller enden",
        lowercase: "${path} må kun indeholde af små bogstaver",
        uppercase: "${path} må kun indeholde af stove bogstaver",
    },
    number: {
        min: "${path} skal være større eller lig med ${min}",
        max: "${path} skal være mindre eller lig med ${max}",
        lessThan: "${path} skal være mindre end ${less}",
        moreThan: "${path} skal være større end ${more}",
        notEqual: '${path} må ikke være lig med "${notEqual}"',
        positive: "${path} skal være et positivt tal",
        negative: "${path} skal være et nagativt tal",
        integer: "${path} skal være et tal",
    },
    date: {
        min: "${path} skal være senere end ${min}",
        max: "${path} skal være før end ${max}",
    },
    boolean: {},
    object: {
        noUnknown:
            '${path}-feltet må ikke have nøgler, der ikke er defineret i "Objekt-Shape"',
    },
    array: {
        min: "${path}-feltet skal indeholde mindst ${min} elementer",
        max: "${path}-feltet skal have færre end eller lig med ${max} elementer",
    },
};
