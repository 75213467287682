export const Modal = {
    // Styles for the base style
    baseStyle: {
        overlay: {
            background: "rgba(0,0,0,0.7)",
        },
        dialogContainer: {
            padding: 4,
            ["@supports (height: -webkit-fill-available)"]: {
                height: "100%", // override chakras experimental use of "fill-available" on Modal containers which may cut the modal on mobile devices
            },
        },
        dialog: {
            borderRadius: "card",
            marginY: "auto",
            paddingX: {
                base: 4,
                lg: 10,
            },
            paddingY: {
                base: 10,
                lg: 10,
            },
            width: "full",
            maxWidth: "xl",
        },
        header: {
            fontSize: "h3",
            fontWeight: "black",
            padding: 0,
        },
        body: {
            padding: 0,
            marginBottom: 0,
        },
        footer: {
            padding: 0,
            marginTop: 5,
            justifyContent: "flex-start",
            alignItems: "flex-start",
        },
        closeButton: {
            top: 4,
            right: 4,
            fontSize: "lg",
        },
    },
    // Styles for the size variations
    sizes: {
        default: {
            dialog: {
                maxWidth: "xl",
            }
        },
        large: {
            dialog: {
                maxWidth: "container.lg",
            }
        },
        xxlarge: {
            dialog: {
                maxWidth: "2xl",
            }
        }
    },
    // Styles for the visual style variations
    variants: {
        confirmation: {
            footer: {
                justifyContent: "center",
                marginTop: 10,
                "*": {
                    flex: "1 1 auto",
                },
            },
        },
    },
    // The default `size` or `variant` values
    defaultProps: {
        size: "default",
    },
};
//
