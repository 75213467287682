import axios from "axios";

/**
 * =====================================================
 * FLEXII API NEXT.JS
 * =====================================================
 */
/**
 * Axios config making request to Flexii Next.js backend.
 * Used for client side request to "api/pages/" API's
 */
export const apiFlexii = axios.create({
    baseURL: "/api",
});
