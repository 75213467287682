/**
 * Convert rem size to Pixel
 * @see https://stackoverflow.com/a/42769683
 * @param {string | number} rem Size in rem
 * @returns {number} Size in px
 */
export function convertRemToPixels(rem) {
    return rem && typeof window !== "undefined"
        ? parseFloat(rem) *
              parseFloat(getComputedStyle(document.documentElement).fontSize)
        : 0;
}
